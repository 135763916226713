import React from "react"
import styled from "styled-components"

import Breakpoints from "./vars/breakpoints"
import Color from "./vars/colors"
import Container from "./containers"
import Colors from "../components/vars/colors"

const StyledDiversityStatement = styled.section`
    // styles
    background: #fff;
    padding: 0 0 4rem 0;
    margin-bottom: 2rem;

    h4 {
        margin-bottom: 1.25rem;
    }

    p {
        font-size: 1.1em;
    }
`

const DiversityStatement = (props) => {
    return (
        <StyledDiversityStatement>
            <Container.Margin tag="div" className="GenAnalytics">
                <h4>A vision for diverse recruitment.</h4>
                <p>
                VizCareer is a young business with big ambitions for the future. We know that innovation and technology is changing the face of the recruitment sector and we want to ensure that diversity and inclusion is at the heart of these changes. We know that businesses perform best when they hire and retain diverse talent. We will support you to help achieve your diversity and inclusion ambitions through our unique hiring tool and recruitment process.
                </p>
            </Container.Margin>
        </StyledDiversityStatement>
    )
}

export default DiversityStatement;