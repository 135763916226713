import React from "react"
import styled from "styled-components"

import Breakpoints from "./vars/breakpoints"
import Color from "./vars/colors"
import Container from "./containers"
import Colors from "../components/vars/colors"
import Link from "./elements/links"

import logo from "../images/other-logo/ga-logo.jpg"

const StyledClientQuote = styled.section`


background: #fff;
padding: 4rem 0;

h3 {
    small {
        text-transform: uppercase;
        font-size: 0.45em;
        color: ${ Color.grey.g5 };
    }
    margin-bottom: 1.5rem;
}

.quotebody {
    border-left: 5px solid ${ Color.primary.main };
    padding-left: 1rem;
    margin-bottom: 0.5rem;
    :before {
        content: "“";
    }
    :after {
        content: "”"
    }
}

.GenAnalytics {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;

    div:first-child {
        display: flex;
        align-items: center;
        img {
            max-width: 40%;
            display: block;
            margin: 0 auto;
            filter: grayscale(1);
            position: relative;
            transition: 0.2s;
            top: 0rem;
            :hover {
                filter: none;
                top: -0.2rem;
            }
        }
    }
    div:nth-child(2) {
        font-size: 1.1em;
    }

    p a {
        color: ${ Colors.secondary.light };
        font-weight: bold;
    }

    @media only screen and (${ Breakpoints.device.medium }) {
        flex-direction: row;
        div:first-child {
            img {
                max-width: 15rem;
            }
        }
    }
}
`

const ClientQuote = (props) => {
    return (
        <StyledClientQuote id={ props.id }>
            <Container.Margin tag="div" className="GenAnalytics">
                <div>
                    <a href="http://www.GenAnalytics.co.uk/">
                        <img src={logo} alt="GenAnalytics Logo" />
                    </a>
                </div>
                <div>
                    <h3>
                        <small>Diversity &amp; Inclusion</small><br />
                        Access talent from any background
                    </h3>
                    <p className="quotebody">{ props.body }</p>
                    <p>
                        <a href="http://www.GenAnalytics.co.uk/" target="_blank">GenAnalytics.co.uk</a>
                    </p>
                </div>
            </Container.Margin>
        </StyledClientQuote>
    )
}


export default ClientQuote;