import React from "react"
import styled from "styled-components"

import Breakpoints from "./vars/breakpoints"
import Color from "./vars/colors"
import Container from "./containers"
import Colors from "../components/vars/colors"
import Link from "./elements/links"

import ChangeLogo from "../images/clients-logo/change-logo.jpg"
import EnvironmentalStandardsLogo from "../images/clients-logo/EnvironmentalStandards.svg"
import GovLogo from "../images/clients-logo/gov.jpg"
import HeadMedicalLogo from "../images/clients-logo/head-medical.png"
import HeadResourcingLogo from "../images/clients-logo/head-resourcing.png"
import IntelLogo from "../images/clients-logo/Intel_logo.png"
import MakayLogo from "../images/clients-logo/makay.png"
import MunroLogo from "../images/clients-logo/munro.svg"
import MurryLogo from "../images/clients-logo/MurryLogo.png"
import NableLogo from "../images/clients-logo/n-able.svg"
import NorthernMarineLogo from "../images/clients-logo/northern-marine.svg"
import PertempsLogo from "../images/clients-logo/pertemps.png"
import RecruitmentIQLogo from "../images/clients-logo/recruitmentIQ.jpg"
import S1JobsLogo from "../images/clients-logo/s1jobs.png"
import ScottishFiscalComissionLogo from "../images/clients-logo/scottish-fiscal-commission.png"
import SearchLogo from "../images/clients-logo/search.svg"
import VirtuosoLogo from "../images/clients-logo/virtuoso.svg"
import X1JobsLogo from "../images/clients-logo/x1jobs.jpg"


const StyledClientsList = styled.section`
background: #fff;
padding: 4rem 0;

p {
    margin: 1rem 0;
    font-size: 1.05em;
}

ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 2rem;
}

ul li {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 0;
    img {
        width: 10rem;
        align-self: center;
    }
}

    @media only screen and (${ Breakpoints.device.medium }) {
        /* Large screens only */
    }
}
`

const ClientsList = (props) => {
    return (
        <StyledClientsList id={ props.id }>
            <Container.Margin tag="div" className="ClientsList">
                <h2>
                    Our Clients
                </h2>
                <p>Don’t just take our word for it - read what our clients have to say about their experiences working with us. VizCareer has helped businesses find great candidates and save time and money with our video recruitment solutions. Take a look around and see what our clients have to say. Contact us if you have any questions or would like to learn more about our services.</p>
                <ul>
                    <li><img src={ChangeLogo} alt="Change Recruitment" /></li>
                    <li><img src={ EnvironmentalStandardsLogo } alt="EnvironmentalStandards Logo" /></li>
                    <li><img src={ GovLogo } alt="Gov Logo" /></li>
                    <li><img src={ HeadMedicalLogo } alt="HeadMedical Logo" /></li>
                    <li><img src={ HeadResourcingLogo } alt="HeadResourcing Logo" /></li>
                    <li><img src={ IntelLogo } alt="Intel Logo" /></li>
                    <li><img src={ MakayLogo } alt="Makay Logo" /></li>
                    <li><img src={ MunroLogo } alt="Munro Logo" /></li>
                    <li><img src={ MurryLogo } alt="Murry Logo" /></li>
                    <li><img src={ NableLogo } alt="Nable Logo" /></li>
                    <li><img src={ NorthernMarineLogo } alt="NorthernMarine Logo" /></li>
                    <li><img src={ PertempsLogo } alt="Pertemps Logo" /></li>
                    <li><img src={ RecruitmentIQLogo } alt="RecruitmentIQ Logo" /></li>
                    <li><img src={ S1JobsLogo } alt="S1Jobs Logo" /></li>
                    <li><img src={ ScottishFiscalComissionLogo } alt="ScottishFiscalComission Logo" /></li>
                    <li><img src={ SearchLogo } alt="Search Logo" /></li>
                    <li><img src={ VirtuosoLogo } alt="Virtuoso Logo" /></li>
                    <li><img src={ X1JobsLogo } alt="X1Jobs Logo" /></li>
                </ul>
            </Container.Margin>
        </StyledClientsList>
    )
}


export default ClientsList;