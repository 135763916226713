import React from "react"
import styled from "styled-components"

import Breakpoints from "./vars/breakpoints"
import Color from "./vars/colors"
import Container from "./containers"
import Colors from "../components/vars/colors"

const StyledNetTalentTestimonial = styled.section`
background: ${ Colors.tertiary.main };
color: ${ Colors.text.inverted };
padding: 4rem 0;

    blockquote {
        font-size: 1.5em;
        border-left: 15px solid ${ Colors.primary.main };
        padding: 1rem;
        position: relative;
        &:before {
            content: "“"; // Left quotation mark
            position: absolute;
            font-size: 18rem;
            color: ${ Colors.primary.main };
            opacity: 0.15;
            top: 55px;
        }
        &:after {
            content: "”"; // Left quotation mark
            position: absolute;
            font-size: 18rem;
            color: ${ Colors.primary.main };
            opacity: 0.15;
            bottom: -45px;
            right: 0;
        }
    }
    .netTalentSource {
        display: block;
        margin-top: 1rem;
        margin-left: calc(15px + 1rem);
        font-size: 110%;
    }
    a {
        color: ${ Color.primary.main }
    }

    @media only screen and (${ Breakpoints.device.medium }) {
        /* Large screens only */
    }
}
`

const NetTalentTestimonial = (props) => {
    return (
        <StyledNetTalentTestimonial id={ props.id }>
            <Container.Margin tag="div" className="NetTalentTestimonial">
                <div>
                    <blockquote>
                        The engagement that candidates get from a video advert versus a text advert is phenomenal. VizCareer lets us stand out in a sea of job adverts so when people watch it, they will remember it.
                    </blockquote>
                    <span span class="netTalentSource">
                        Shannon McKechnie - <span class="netTalentTitle">Director at <a href="https://nettalent.net">Net Talent</a></span>
                    </span>
                </div>

            </Container.Margin>
        </StyledNetTalentTestimonial>
    )
}


export default NetTalentTestimonial;