import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Colors from "../vars/colors"


// Inline link styling
const TextLinkStyle = styled(props => <Link {...props} />)`
&:link {
    color: ${ Colors.secondary.main };
    font-weight: bold;
}
&:visited {
    color: ${ Colors.secondary.light };
}
`
const ExTextLinkStyle = styled.a`
    &:link {
        color: ${ Colors.secondary.main };
        font-weight: bold;
    }
    &:visited {
        color: ${ Colors.secondary.light };
    }
`
// Text Link
// Include prop external to bypass Gatsby Link api 
// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/
const TextLink = (props) => {
    if (props.external) {
        return (
            <ExTextLinkStyle {...props}>
                { props.children }
            </ExTextLinkStyle>
        )
    } else return (
        <TextLinkStyle to={ props.href }>
            { props.children }
        </TextLinkStyle>
    )
}


//
// Regular small button
//
const ButtonLinkStyle = styled(props => <Link {...props} />)`
    color: ${ Colors.primary.contrastText };
    text-decoration: none;
    background-color: ${ Colors.primary.main };
    font-weight: bold;
    padding: 0.65em;
    display: inline-block;
    border-radius: 0.3rem;
    text-transform: uppercase;

    position: relative;
    transition: all 0.5s ease;
    top: 0;

    &:hover {
        top: -0.1rem;
        background-color: ${ Colors.primary.light };
        box-shadow: 0 0.1rem #00000020;
    }
`
const ExButtonLinkStyle = styled.a`
    color: ${ Colors.primary.contrastText };
    text-decoration: none;
    background-color: ${ Colors.primary.main };
    font-weight: bold;
    padding: 0.65em;
    display: inline-block;
    border-radius: 0.3rem;
    text-transform: uppercase;

    position: relative;
    transition: all 0.5s ease;
    top: 0;

    &:hover {
        top: -0.1rem;
        background-color: ${ Colors.primary.light };
        box-shadow: 0 0.1rem #00000020;
    }
`
const ButtonLink = (props) => {
    if (props.external) {
        return (
            <ExButtonLinkStyle href={ props.href }>
                { props.children }
            </ExButtonLinkStyle>
        ) 
    } else return (
        <ButtonLinkStyle to={ props.href }>
            { props.children }
        </ButtonLinkStyle>
    )
}



// Ghost button
// Regular small button
const GhostButtonLinkStyle = styled(props => <Link {...props} />)`

    border: 0.1em solid ${ Colors.grey.g5 };
    color: ${ Colors.text.primary };
    text-decoration: none;
    font-weight: bold;
    padding: 0.65em;
    display: inline-block;
    border-radius: 0.3rem;
    text-transform: uppercase;

    position: relative;
    transition: all 0.5s ease;
    top: 0;

    &:hover {
        top: -0.1rem;
        color: ${ Colors.primary.dark };
        border: 0.1em solid ${ Colors.primary.dark };
        background: #fff;
        box-shadow: 0 0.1rem;
    }

    &:active {
        transition: none;
        top: 0;
        box-shadow: 0 0;
    }
`
const GhostButtonLink = (props) => {
    return (
        <GhostButtonLinkStyle to={ props.href }>
            { props.children }
        </GhostButtonLinkStyle>
    )
}

const components = { TextLink, ButtonLink, GhostButtonLink }
export default components;