import React from "react"
import styled from "styled-components"
import Containers from "./containers"
import Fade from "react-reveal"

import Breakpoints from "./vars/breakpoints"
import Colors from "./vars/colors"
import ButtonLink from "./elements/links.js"


const StyledLarge = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #FFF;
    margin-bottom: 5rem;

    background: ${Colors.tertiary.main};
    background: ${Colors.tertiary.main};
    background: linear-gradient(27deg, ${Colors.tertiary.dark} 0%,
                                       ${Colors.tertiary.main} 20%,
                                       ${Colors.primary.dark} 80%,
                                       ${Colors.primary.main} 100% );

    border-radius: 0.6rem;
    text-align: center;

    padding: 5rem 5%;
    @media only screen and (${ Breakpoints.device.small }) { padding: 5rem 8%; }
    @media only screen and (${ Breakpoints.device.medium }) { padding: 5rem 20%; }
    @media only screen and (${ Breakpoints.device.large }) { padding: 5rem 25%; }

    h1, h2, h3, h4, h5, h6 { text-transform: uppercase; }
    h2 { 
        font-size: 2.5em;
        border-bottom: 2px solid
        rgba(255,255,255,0.2);
        small {
            font-size: 0.45em;
            color: rgba(255,255,255,0.6);
        }
    }
    p { font-size: 1.1em; margin-top: 0.75rem;}

    a { margin-top: 2rem; }

` // styles
const Large = (props) => {
    if (props.fade)
    {
        return (
                <Containers.Margin>
                    <StyledLarge className={ props.className }>
                        <Fade bottom>
                            <h2><small>Get Started</small><br />{ props.heading }</h2>
                            <p>{ props.body }</p>
                            <ButtonLink.ButtonLink external href="mailto:contact@vizcareerdigital.com?subject=We'd like a demo!">Book a demo</ButtonLink.ButtonLink>
                        </Fade>
                    </StyledLarge>
                </Containers.Margin>
        )
    } else {
        return (
            <Containers.Margin>
                <StyledLarge className={ props.className }>
                    <h2><small>Get Started</small><br />{ props.heading }</h2>
                    <p>{ props.body }</p>
                    <ButtonLink.ButtonLink external href="mailto:contact@vizcareerdigital.com?subject=We'd like a demo!">Book a demo</ButtonLink.ButtonLink>
                </StyledLarge>
            </Containers.Margin>
        )
    }
}

const values = { Large };
export default values;