import React from "react"
import styled from "styled-components"

import Containers from "../containers"
import Breakpoints from "../vars/breakpoints"
import ButtonLink from "../elements/links.js"



const LandingHeader = styled.div`
    section {
        display: flex;
        flex-direction: column;

        div:first-child {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
        }

        div:nth-child(2) {
            flex: 0.5;
        }

    }

    h1 {
        margin-top: 1rem;
        font-size: 3em;

        @keyframes HomeHeaderWords {
            from { transform: translateY(5rem); }
            to   { transform: translateY(0rem); }
        }

        .wordcontainer {
            overflow: hidden;
            display: inline-flex;
            span {
                margin-right: 0.9rem;
                transform: translateY(5rem);

                display: inline-block;
                animation-name: HomeHeaderWords;
                animation-duration: 1s;
                animation-fill-mode: forwards;
            }
        }
    }

    @keyframes HomeHeaderBody {
        from { transform: translateY(4rem); opacity: 0;}
        to { transform: translateY(0rem); opacity: 1;}
    }

    .headeranimate {
        opacity: 0;
        animation-name: HomeHeaderBody;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    p {
        margin-top: 0.5rem;
        font-size: 1.5em;
    }

    .actions {
        margin-top: 1rem;
        a { margin-right: 1rem; }
        a:last-child { margin-right: 0; }

    }

    @media only screen and (${ Breakpoints.device.small }) {
        h1 { font-size: 3.75em; }
    }

    @media only screen and (${ Breakpoints.device.medium }) {
        h1 { font-size: 4.25em; }
        section {
            flex-direction: row;
        }
    }
`


const HomeHeader = (props) => {

    // Get the words in the header and split up the text into spans for animation
    const splitstring = props.HeaderText.split(" ");

    let headerWords = splitstring.map((word, i) => {
        return (
            <span className="wordcontainer" key={ i }>
                <span style={{ animationDelay: i / 5 + "s" }}>
                    { word }
                </span>
            </span>
        )
    })

    const animatedelay = splitstring.length / 5;
    const delayOne = { animationDelay: animatedelay * 1.2 + "s" }
    const delayTwo = { animationDelay: animatedelay * 1.4 + "s" }

    return (
        <LandingHeader as="header">
            <Containers.Margin>
                <div>
                    <h1>{ headerWords }</h1>
                    <p className="headeranimate" style={ delayOne }>{ props.BodyText }</p>
                    <div className="actions headeranimate" style={ delayTwo }>
                        <ButtonLink.ButtonLink external href="mailto:contact@vizcareerdigital.com?subject=We'd like a demo!">Book a demo</ButtonLink.ButtonLink>
                        {/* <ButtonLink.GhostButtonLink href="/" >See it in action</ButtonLink.GhostButtonLink> */}
                    </div>
                </div>
                <div></div>
            </Containers.Margin>
        </LandingHeader>        
    )
}

export default HomeHeader;