import React from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import Fade from 'react-reveal/Fade';

import Main from "../components/layout"
import Container from "../components/containers"
import HomeHeader from "../components/headers/home"
import Footer from "../components/footers"
import Colors from "../components/vars/colors"
import PrimaryNav from "../components/nav/PrimaryNav"
import CallToAction from "../components/calltoaction"
import GenAnalytics from "../components/genanalytics"
import DiversityStatement from "../components/DiversityStatement"
import ClientsList from "../components/ClientsList"
import NetTalentTestimonial from "../components/NetTalentTestimonial"

import hiresvg from "../images/illustration/hire.svg"
import interfacesvg from "../images/illustration/interface.svg"
import Oilcan from "../images/svg-icons/oil-can-solid.svg"
import Microphone from "../images/svg-icons/microphone-alt-solid.svg"
import Thumbsup from "../images/svg-icons/thumbs-up-solid.svg"
import Wrench from "../images/svg-icons/wrench-solid.svg"
import Lock from "../images/svg-icons/lock-solid.svg"
import StarEyes from "../images/svg-icons/grin-stars-solid.svg"






// Page specific
// Extend existing components
const StyledHeadingPane = styled(Container.FlexMedium)`
    padding: 6rem 0;
    gap: 3.5rem;
    p { font-size: 1.1em }
    p { margin-top: 1rem; }
    img { max-width: 100% }
    div:first-child {
        flex: 1.25;
    }
    div:nth-child(2) {
        flex: 1;
        display: flex;
        align-items: center;
    }

    small {
            text-transform: uppercase; font-size: 0.45em; color: ${ Colors.grey.g5 };
        }
`
const HeadingPane = (props) => {
    return (
        <Container.Margin>
            <StyledHeadingPane>
                <div>
                    <h2><small>Video First</small><br />Look deeper than the CV</h2>
                    <p>
                        Video replaces the tedious element of recruitment with an engaging wall of real-life faces. Scale any stage of your process with personable interviews: impractical in person, seamless on-demand.
                    </p>
                    <p>
                        Hand-pick which features you need. They can work independently or in perfect harmony: all in one platform. Categorise applications, choose who progresses to a live interview stage, and use different tools to truly build a hiring process that works for you.
                    </p>
                </div>
                <div><img src={ hiresvg } alt="A person looks at candidate details on screens" /></div>
            </StyledHeadingPane>
        </Container.Margin>
    )
}






const StyledFeaturesPane = styled(Container.Fullwidth)`
    background: ${ Colors.grey.g8 };
    padding: 5rem 0;
    svg {
        height: 2rem;
    }
    .oilcan path, .wrench path { fill: ${Colors.primary.dark}; }
    .two path, .lock path{ fill: ${Colors.secondary.main}; }
    .three path, .stareyes path{ fill: ${ Colors.tertiary.main }; }
    h3 { font-size: 1.5em; margin-top: 1rem; }
    p { font-size: 1.05em; margin-top: 0.75rem; }
    .featurescontainer {
        gap: 2rem;
    }
`
const FeaturesPaneOne = () => {
    return (
        <StyledFeaturesPane>
            <Container.Margin tag='div'>
                <Container.FlexMedium className="featurescontainer">
                    <div>
                        <Oilcan className="oilcan"/>
                        <h3>Frictionless applications</h3>
                        <p>Creating a job on VizCareer is straight forward, and the application process is no different. Get all the information you need easily.</p>
                    </div>
                    <div>
                        <Microphone className="two"/>
                        <h3>Live calls</h3>
                        <p>Schedule live interviews and utilise our powerful tools to keep things organised – record calls, watch them back, take as much time as you need.</p>
                    </div>
                    <div>
                        <Thumbsup className="three"/>
                        <h3>Remove barriers</h3>
                        <p>Make geographical boundaries a thing of the past and widen your candidate pool. People who would never have met, meet.</p>
                    </div>
                </Container.FlexMedium>
            </Container.Margin>
        </StyledFeaturesPane>
    )
}

const FeaturesPaneTwo = () => {
    return (        
    <StyledFeaturesPane>
        <Container.Margin tag='div'>
            <Container.FlexMedium className="featurescontainer">
                <Fade bottom>
                    <div>
                        <Wrench className="wrench"/>
                        <h3>Brand it up</h3>
                        <p>Bring your organisation to life with a visually distinctive video that will turn heads in a crowded market and inspire people to apply. </p>
                    </div>
                    <div>
                        <Lock className="lock"/>
                        <h3>Stage builder</h3>
                        <p>You can have as many stages as you like for your recruitment process; filter through all your applicants until you have the perfect candidate for the role. </p>
                    </div>
                    <div>
                        <StarEyes className="stareyes"/>
                        <h3>Invite only jobs</h3>
                        <p>Jobs don’t need to be public facing. Create vacancies and invite whoever you want - this way you can promote internal roles exclusively to your business. </p>
                    </div>
                </Fade>
            </Container.FlexMedium>
        </Container.Margin>
    </StyledFeaturesPane>
    )
}






const StyledModulesPane = styled.div`
    .modulescontainer {
        gap: 3.5rem;
        margin: 3rem 0;

        img { max-width: 100%; }
        .modulesimg { flex: 1.25; display: flex; }
        .modulesbody {
            flex: 1;
            
        }
    }


    h3 {
        small {
            text-transform: uppercase; font-size: 0.45em; color: ${ Colors.grey.g5 };
        }
    }

    h5 {
        font-size: 1.25em;
    }

    h4, h5 { margin-top: 1.15em; }
    p { font-size: 1.15em; margin-top: 0.5em; }
`
const ModulesPane = () => {
    return (
        <Container.Margin>
            <StyledModulesPane>
                <Container.FlexMedium className="modulescontainer">
                    <div className='modulesimg'>
                        <img src={ interfacesvg } alt="A person looks over a trendy interface." />
                    </div>
                    <div className='modulesbody'>
                        <Fade bottom>
                            <div>
                                <h3><small>Modularity</small><br />Flexible tools for efficient teams<br /></h3>
                                <h5>Showcase</h5>
                                <p>Showcase your job opportunity with eye-catching video and attract the best talent internationally.
                                </p>
                            </div>
                            <div>
                                <h5>Audition</h5>
                                <p>A video interview process where anyone can respond to you - keeping questions consistent and fair, at scale.
                                </p>
                            </div>
                            <div>
                                <h5>Talent</h5>
                                <p>Replace tedious CV’s with a wall of real-life faces answering the questions you’ve set them.
                                </p>
                            </div>
                            <div>
                                <h5>Live</h5>
                                <p>Interact with candidates live without limits on attendees or time. Recording on our platform is entirely GDPR compliant.
                                </p>
                            </div>
                            <div>
                                <h5>Assessment Centre</h5>
                                <p>No need to book rooms, premises, or assessors. Save time, money, and produce significant carbon efficiencies.
                                </p>
                            </div>
                        </Fade>
                    </div>
                </Container.FlexMedium>
            </StyledModulesPane>
        </Container.Margin>
    )
}









// Build the page
export default function Index() {

    return (
        <React.Fragment>
            <Helmet>
                <title>VizCareer Digital | Video recruitment to showcase you at your best.</title>
                <link rel="canonical" href="https://vizcareer.com" />
            </Helmet>
            <PrimaryNav />
            <HomeHeader
                HeaderText="Integrated video-first platform to meet all of your recruitment needs"
                BodyText="Bring personality to the forefront of your hiring process and let video do the talking." />
            <Main>
                <HeadingPane />
                <FeaturesPaneOne />
                <Fade bottom><ModulesPane /></Fade>
                <FeaturesPaneTwo />
                <ClientsList />
                <NetTalentTestimonial />
                    <GenAnalytics
                        body="GenAnalytics performed a diversity and inclusion audit of VizCareer and its recruitment platform. Our recommendations will support VizCareer to build on their determination to demonstrate that Diversity and Inclusion are key pillars of their platform. We are pleased to endorse VizCareer for organisations looking to achieve D&amp;I metrics in their recruitment process and attract a diverse range of candidates using the site."
                        id="gen-analytics"
                    />
                    <DiversityStatement />
                <CallToAction.Large fade
                    heading="Hire with Oomph"
                    body="Enjoy a fairer, greener and more efficient hiring process with VizCareer by your side. Why not get in touch to arrange a free demo - we offer the best customer support in the industry and we’d love to show you"
                />
            </Main>
            <Footer />
        </React.Fragment>
    )
}